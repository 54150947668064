<template>
    <div class="event-Container">
        <div class="nav--backButton" v-if="selected_venue">
            <span class="button is-link is-small" style="background-color: #333">More Cofee in</span>
            <span>-></span>
            <router-link :to="{ path: '/' + selected_venue.city?.name?.toLowerCase() }" class='button is-link is-small'>
                 {{selected_venue.city?.name}}  
            </router-link>
            <span>-></span>
            <router-link :to="{ path: `/${selected_venue.city?.name?.toLowerCase()}/districts/${selected_venue.district?.name?.toLowerCase()}` }" class='button is-link is-small'>
                {{selected_venue.district?.name}} 
            </router-link>
        </div>
        <div class="event-headerImage">
            <img :src="selected_venue.image">
        </div>

        <div class="event-aboutSection">
            <span class="event-Category">{{selected_venue.category?.name}}</span>
            <h1 class="subtitle-1">{{selected_venue.name}}</h1>
            
            <!-- <h2 class="subtitle-2">About the place </h2> -->
            <p v-html="selected_venue.description"></p>

        </div>

        <div class="event-metaContainer">
            <div class="event-cardMeta" v-if="isVenueLoaded() == true">
                <h2 class="subtitle-2">Quickfeel</h2>
                <rating :venue="selected_venue" ></rating>      
            </div>

           <section class="event-metaSection">
            
            <a v-bind:href="selected_venue.website">{{selected_venue.website}}</a>
            
           </section>
            
            <section class="event-metaSection">

                <h2 class="subtitle-2">Map</h2>
                
                 <l-map  
                    v-if="isVenueLoaded() == true"
                    style="height: 20vh"
                    v-model="zoom"
                    v-model:zoom="zoom"
                    :center="[marker.latitude, marker.longitude]">

                    <l-tile-layer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    ></l-tile-layer>
           
                    <l-marker :lat-lng="[marker.latitude, marker.longitude]">
                        <l-icon :icon-url="iconUrl" />
                        <l-popup>
                        lol
                        </l-popup>
                    </l-marker>
           

                </l-map>
                   <div class="event-cardAttribute">
                            <div class="card-eventIcon " title=""><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                            </div>
                            <span>
                                {{ selected_venue.location }}</span>
                            <!-- </a> -->
                        </div>
                 <div class="event-linkToMap">
                    <a v-bind:href="'http://maps.google.com/maps?daddr=' + selected_venue.address + '&directionsmode=driving'" target="_blank" >Show route</a>
                </div>

            </section>
             <section class="event-metaSection">
                
                <h2 class="subtitle-2">Read more</h2>
                <ul>
                    <li><a v-bind:href="selected_venue.facebook" target="_blank">{{selected_venue.facebook}}</a></li>
                    <li><a v-bind:href="selected_venue.twitter" target="_blank">{{selected_venue.twitter}}</a></li>
                </ul>
             </section>
        </div>

       
    </div>
</template>

<script>
    import "leaflet/dist/leaflet.css";
    import Rating from '../components/Rating';
    import {    LMap,
                LIcon,
                LTileLayer,
                LMarker,
                LPopup } from "@vue-leaflet/vue-leaflet";

    import { mapState } from 'vuex';

    export default {
        components: {
            LMap,
            LIcon,
            LTileLayer,
            LMarker, 
            LPopup,
                  
            Rating
        },
        mounted() {
            if(!this.isVenueLoaded()) {
                this.$store.dispatch("getSelectedVenue",  this.$route.params.slug);
            }
        },
        data() {
            return {
                // Map init data
                marker: {
                    latitude: 0,
                    longitude: 0
                },
                zoom: 14,
                iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  
                iconUrl: require('leaflet/dist/images/marker-icon.png'),  
                shadowUrl: require('leaflet/dist/images/marker-shadow.png')  
            }
        },
        async beforeMount() {
            // HERE is where to load Leaflet components!            
        },
        methods: {
            isVenueLoaded() {
                return Object.keys(this.selected_venue).length > 1;
            },
            updateDocumentTitle() {
                let documentTitle = `${this.selected_venue.name} | ${this.selected_venue.city.name} ${this.selected_venue.district.name}`
                document.title = documentTitle;
            },
            updateMarkerPosition(){
                this.marker.latitude = this.selected_venue.latitude
                this.marker.longitude = this.selected_venue.longitude
            }
        },
        watch: {
          selected_venue() { 
            this.updateMarkerPosition();
            this.updateDocumentTitle(); 
          }    
        },
        computed: {
            ...mapState(['selected_venue'])
        }
    }
</script>