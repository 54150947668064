<template>
<h2 class="subtitle-1" style="position: absolute; top: 40px; z-index: 9999; padding:0.5em; background: rgba(0,0,0,0.9);" >
  Featured in {{ formatForTitle(city_slug) }}
</h2>
<swiper :spaceBetween="30" :centeredSlides="true" :autoplay='{
  "delay": 2500,
  "disableOnInteraction": false
}' :pagination='{
  "clickable": true
}' :navigation="true" class="mySwiper">

    <swiper-slide v-for="venue in venues.slice(-2)" :key="venue.id">
        <section class="events-Featured" @click="featured_clicked(venue)">
        <div style="position: relative;" class="featured--imageContainer">
            <img  v-bind:src="venue.image" />
        </div>
        <div class="container">
          <!-- <span class="event-Category">{{venue.category.name}}</span> -->
          <h1 class="subtitle-1">{{ venue.name }}</h1>
           <div class="card-Meta">
                <div class="card--eventAttribute" style="flex-direction: column;">
                    <span v-html="venue.description"></span> 
                    <span>{{ venue.location }}</span>
                    <!-- </a> -->
                </div>      
        </div>
        </div>
        
    </section>  

    
    </swiper-slide>
    
  </swiper>
    
    
</template>

<script>
import { mapState } from 'vuex';
import { Swiper, SwiperSlide } from 'swiper/vue';
// import  Rating  from '../components/Rating.vue';

import SwiperCore, {
  Parallax,Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Parallax,Pagination,Navigation]);

export default {
  name: 'FeaturedEvent',
  components: {
      Swiper,
      SwiperSlide,
      
    },
  methods: {
    featured_clicked(venue) {
      console.log(venue)
      this.$router.push( {name: 'Venue', params: {slug: venue.slug}} )
    },
    formatForTitle(string) {
      if(string){
        return string.replace(/,/g, " ")
          .charAt(0)
          .toUpperCase() + string.slice(1);
      }
    }     
  },
  props: {
    msg: String
  },
  data() {
        return {
            chartData: {
                labels: ['Price', 'Coffee', 'Atmosphere', 'Workability'],
                datasets: [
                    {
                        backgroundColor: 'rgba(179,181,198,0.2)',
                        borderColor: 'rgba(179,181,198,1)',
                        pointBackgroundColor: 'rgba(179,181,198,1)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgba(179,181,198,1)',
                        data: [65, 59, 90, 81]
                    }
                ]
            },
            chartOptions: {
                plugins: {
                    legend: {
                        
                      display: false,
                      position: 'bottom',
                      color: '#495057'
                    
                    }
                },
                scales: {
                    r: {
                        pointLabels: {
                            color: '#495057',
                        },
                        grid: {
                            color: '#ebedef',
                        },
                        angleLines: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        }
    },
  computed: 
    // Getting mapped states from vuex
    mapState(['venues', 'city_slug']) 
}
</script>
