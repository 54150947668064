import { createStore } from "vuex" 
import { createLogger } from 'vuex'
import axios from 'axios'

const store = createStore({
   plugins: [createLogger()],

   state:{
      venues: [],
      selected_venue: {},
      featured_venues: [],
      categories: [],
      category_slug: 'notset',
      city_slug: 'notset',
      district_slug: 'notset',
      bounding_box: {},
      criteria: '',
      current_position: {}
   },
   actions: {
    getVenues({ commit }) {
        let params = {
            category_slug: this.state.category_slug,
            city_slug: this.state.city_slug,
            district_slug: this.state.district_slug,
            bounding_box: this.state.bounding_box,
            criteria: this.state.criteria,
            current_position: this.state.current_position
        }
        axios
            .get(`${process.env.VUE_APP_API_BASE}/venues`, { params })
            .then(response => {
                commit('SET_VENUES', response.data)
            })
    },  
    getSelectedVenue( { commit }, payload) {
        console.log(payload);
        let params = { slug: payload}
        axios
            .get(`${process.env.VUE_APP_API_BASE}/venues/${params.slug}`, { params })
            .then(response => {
                commit('SET_SELECTED_VENUE', response.data)
            })
    },
    getCategories({ commit }) {
        let params = {}

        axios
            .get(`${process.env.VUE_APP_API_BASE}/categories`, { params })
            .then(response => {
                commit('INSERT_CATEGORIES', response.data);
            })
        }
    },
    mutations: {  
        SET_SELECTED_VENUE(state, data) {
            state.selected_venue = data.venue;
        },
        CLEAR_SELECTED_VENUE(state, slug) {
            state.selected_venue = {description: `Loading ${slug}`}
        },
        SET_CURRENT_POSITION(state, pos) {
            state.current_position = pos;
        },
        CLEAR_CURRENT_POSITION(state) {
            state.current_position = {};
        },
        SET_VENUES(state, venues) {
            state.venues = venues.results
            state.featured_venues = venues.featured

        },
        INSERT_CATEGORIES(state, results) {
            state.categories = results.categories
            state.locations = results.locations
        },
        SET_CATEGORY(state, category_slug) {
            if(category_slug == null ) { state.category_slug = 0}
            else {
                state.category_slug = category_slug
            }
        },
        SET_BOUNDING_BOX(state, box) {
            state.bounding_box = box
        },
        SET_CITY(state, city) {
            state.city_slug = city
        },
        SET_DISTRICT(state, district) {
            state.district_slug = district
        },
        SET_CRITERIA(state, criteria) {
            state.criteria = criteria
        }
    },
    
})

export default store

