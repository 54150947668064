<template>
<Chart type="radar" :data="chartData" :options="chartOptions" style="height: 200px;" />
  <!-- <v-chart class="chart" :option="option" /> -->
</template>

<script>

import { ref } from "vue";
import Chart from "primevue/chart";

export default {
  name: 'Rating',
  components: {
    Chart
  },    
  props: {
    venue: {}
  }, 
  mounted() {
    // console.log(`mounted Rating with ${this.venue.name}`);
    // Chart.resize();
  },
  setup(props) {
        const chartData = ref({
            labels: ['Price', 'Atmosphere', 'Workability', 'Coffee'],
            datasets: [
                {
                    label: 'Reviews',
                    backgroundColor: 'rgba(255,99,132,0.2)',
                    borderColor: 'rgba(255,99,132,1)',
                    pointBackgroundColor: 'rgba(255,99,132,1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255,99,132,1)',
                    data: [props.venue.price, props.venue.atmosphere, props.venue.workability, props.venue.coffee_quality]
                }
            ]
        });

        const chartOptions = ref({
            plugins: {
                legend: {
                  display: false,
                    labels: {
                        color: '#495057'
                    }
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            // aspectRatio: 1.9,
            scales: {
                r: {
                    beginAtZero: true,
                    min: 0,
                    max: 5,
                    pointLabels: {
                        color: '#495057',
                    },
                    grid: {
                        color: '#ccc',
                    },
                    angleLines: {
                        color: '#ebedef'
                    }
                }
            }
        });

		return { chartData, chartOptions }
    }  
}
</script>

<style scoped>
.p-chart {
  width: 300px;
  height: 250px;
  position: relative;
}

</style>  