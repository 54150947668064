import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/home.vue";
import About from "@/views/about.vue";
import Venue from "@/views/venue.vue";
import LandingPage from "@/views/landing.vue";
import Signup from "@/views/signup.vue";
import Suggestion from "@/views/suggestion.vue";

const routes = [ 
  { path: '/', 
    name: "Landing",
    component: LandingPage,
    props: true 
  },
  {
    path: "/:city_slug", 
    name: "Home",
    component: Home,
    props: true,
    children: [
      {
        path: ':category_slug',
        component: Home,
        props: true
      },
      {
        path: 'districts/:district_slug',
        component: Home,
        props: true
      }
    ]
  },
  {
    path: "/venues/:slug",
    name: "Venue",
    component: Venue,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup
  },
  {
    path: "/suggestion",
    name: "Suggestion",
    component: Suggestion
  },
  {
    path: "/about",
    name: "About",
    component: About,
  }
    
];

  const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'is-active'
  });

  router.beforeEach( (to, from, next) => {
    console.log(to)
    // let documentTitle = `${to.params.title} | ${to.params.location}`

    document.title = "loading...";
    next()
  })
  
  export default router;
