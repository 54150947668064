<template>
  
   <top-navigation v-bind:selectedCity="city_slug"></top-navigation>

    <section class="section pt-small">
      
        <router-view />
      
    </section>

    <footer>
      <div>
        <h2 class="footer-Logo subtitle-2">Inside out guides</h2>
        <span class="footer-socialMedia">
          <font-awesome-icon :icon="[ 'fab', 'facebook' ]" />
          <font-awesome-icon :icon="[ 'fab', 'twitter' ]" />
        </span>
      </div>
      
      <div class="footer-navLinks">
        <a href="/">Start page</a>
        <a href="/about">About us</a>
      </div>
    </footer>
 
</template>

<script>
import { mapState } from 'vuex';
import TopNavigation from './components/TopNavigation.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'App',
  components: {
    TopNavigation, FontAwesomeIcon
  },
  data() {
    return {
      selectedCity: "Berlin",
      activator: false
    }
  },
  methods: {
    makeBurger () {
      this.activator = !this.activator
      return this.activator
    },
  },
  created() {
    // Starting point
    // this.$store.dispatch("getEvents");
    this.$store.dispatch("getCategories");
  },
  computed: 
    // Getting mapped states from vuex
    mapState(['city_slug']) 
   
}
</script>

