<template>
<div class="asdas"> 

    <h3 class="">Name</h3>
    
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
      <InputText v-model="name" placeholder="Name" />
    </div>

    <h3 class="">Description</h3>
      
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
      <TextArea v-model="description" placeholder="Description" />
    </div>

    <h3 class="">Google maps link</h3>
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
      <InputText v-model="location" placeholder="Maps link" />
    </div>
          
    <h3 class="">Pictures</h3>
    <UploadImages @changed="handleImages" uploadMsg="Upload pictures of the place"/>

    <Button label="Submit" @click="submitFiles()"/>

</div>

<Dialog header="Thanks for the tip!" v-model:visible="display" >
	We will have a look and verify as soon as possible.
  <Button label="Ok" @click="this.$router.push(`/${city_slug}`)" />
</Dialog>

</template>

<script>
import UploadImages from "vue-upload-drop-images";
import TextArea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import axios from 'axios';
import {
        mapState
    } from 'vuex';

export default {
  components: {
    UploadImages,
    TextArea,
    InputText,
    Button,
    Dialog
  },
  name: 'Suggestion',
  data () {
    return {
      display: false,
      files: []
    }
  },
  
  methods: {
    handleImages(files){
      console.log(files);
      this.files = files;
      console.log(this.files);
      
    },
    submitFiles(){
    
      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('description', this.description);
      formData.append('location', this.location);

      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];

        formData.append('images[' + i + ']', file);
      }

      axios.post( `${process.env.VUE_APP_API_BASE}/venues/suggest_new_place`,
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }
      ).then(data => {
        console.log(data);
        this.display = true
        // this.$router.push('/temp');
      })
      .catch(data => {
        console.log('FAILURE!!', data);
      });
    }
  },
  computed: {
            ...mapState(['city_slug'])
        }
}
</script>