<template>
    <h1>Temporary landing page</h1>
    <router-link 
        :to="`/berlin`"
        >
        <p>get started</p>
    </router-link>

</template>

<script>
import { useRoute } from 'vue-router';
import { mapState } from 'vuex';

export default {
  name: 'LandingPage',
   mounted() {
        const route = useRoute();
        this.current_route = route;
        
    },
    computed: { 
        // Getting mapped states from vuex
        ...mapState(['locations']) 
    }
}
</script>