<template>
    <section class="events-TopSearch">
        <div class='topSearch-chooseCity'>

            <h1>Find Coffee in</h1>

            <GMapAutocomplete
                placeholder="This is a placeholder"
                selectFirstOnEnter="true"
                @place_changed="handleSearchResult"
                ref="autoCompleteRef" 
                >
            </GMapAutocomplete>
            <div class="btn" v-bind:class="{'btn--clicked': isActive}" @click="getCurrentPosition()">
                <font-awesome-icon icon="location-arrow" />
            </div>
        
        </div>
        <div class="topSearch-chooseCategory">
            
            <ul>
                <li><a @click="handleCategoryClick({slug: '', name: 'Live Music'})">All</a></li>
                <li v-for="category in categories" :key="category">
                    <a @click="handleCategoryClick(category)">{{category.name}}</a>
                </li>
            </ul>
        </div>
    </section>

    <section>
        <ul class="events-timeQuickSelect">
            <li v-for="c in Criterias" :key="c">
                <a v-bind:class="{ 'time-shortcut--is-active': selectedInterval == c }"
                    @click="handleCriteriaClicked(c)">{{c}}</a>
            </li>
        </ul>
    </section>
</template>

<script>
    import { mapState } from 'vuex'; 
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
    
    export default {
        name: 'TopNavigation',
        components: {
            FontAwesomeIcon
        },

        props: {
            // propSelectedCity: String
        },
        setup() {

        },
        data() {
            return {
                selectedInterval: '',
                selectedCity: 1,
                selectedCategory: 0,
                Criterias: [
                    'Coffee',
                    'Atmospheric',
                    'Cheap',
                    'Workability'
                ],
                isActive: false
            }
        },
        mounted() {            
        },
        created() {

        },
        methods: {
            getCurrentPosition() {
                this.isActive = true;
                const $store = this.$store;
                const $router = this.$router;

                navigator.geolocation.getCurrentPosition((position) => {
                    let latlng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }
                    console.log(latlng);
                    this.$store.commit('SET_CURRENT_POSITION', latlng);
                    this.$store.dispatch("getVenues");


                    const $store = this.$store;
                    const $router = this.$router;
                    this.$gmapApiPromiseLazy().then(() => {
                        const geocoder = new google.maps.Geocoder()

                        geocoder.geocode({ location: latlng }, function (results, status) {
                            if (status === 'OK') {
                                let cityArea = results[0].address_components.filter(ac=>~ac.types.indexOf('sublocality'))[0]?.long_name
                                let city = results[0].address_components.filter(ac=>~ac.types.indexOf('locality'))[0]?.long_name
                                
                                let locationString = [cityArea, city].filter(Boolean).join(",");
                                
                                $router.push(`/${locationString}`);
                                $store.commit('SET_CITY', locationString);
                                // $store.dispatch("getVenues");
                            }
                        })
                    })
                    this.isActive = false;
                });
            },
            handleSearchResult(result) {
                this.$store.commit('CLEAR_CURRENT_POSITION');

                console.log(result.formatted_address);
                let box = result.geometry.viewport.toJSON();
                let city_url = result.formatted_address.split(',')[0].toLowerCase();

                this.$store.commit('SET_BOUNDING_BOX', box);
                this.$store.commit('SET_CITY', city_url);

                
                this.$router.push(`/${city_url}`);
                this.$store.dispatch("getVenues");
            },
            handleCriteriaClicked(e) {
                this.$store.commit('SET_CRITERIA', e);
                this.$store.dispatch("getVenues");
            },


            handleCategoryClick(category) {
                console.log(category);
                this.$router.push(`/${this.city_slug}/${category.slug}`)

                this.$store.commit('SET_CATEGORY', category.slug);
                this.$store.dispatch("getVenues");
            },

        },
        watch: {
        
    
            city_slug() {
                console.log('city slug called with')
                console.log(this.city_slug);
            }
            // $route (to){
            //     this.$store.commit('SET_CITY', to.params.city_slug);
            //     this.$store.commit('SET_CATEGORY', to.params.category_slug);
            //     this.$store.dispatch("getVenues");
            // }
        },
         computed: { 
            // Getting mapped states from vuex
            ...mapState(['locations', 'categories', 'city_slug', 'category_slug']) 
        }
    }

    // For reference: This is one getting the current positition and the executing a query for 
    // resolving the city and sub locality
    // 
    // getCurrentPosition() {
    //             const $store = this.$store;
    //             const $router = this.$router;
                
    //             navigator.geolocation.getCurrentPosition((position) => {
    //                 let latlng = {
    //                     lat: position.coords.latitude,
    //                     lng: position.coords.longitude,
    //                 }
    //                 console.log(latlng);
    //                 // let geocoder = new this.VueGoogleMaps.gmapApi.maps.Geocoder();
    //                 this.$gmapApiPromiseLazy().then(() => {
    //                     const geocoder = new google.maps.Geocoder()

    //                     geocoder.geocode({ location: latlng }, function (results, status) {
    //                         if (status === 'OK') {
    //                             let cityArea = results[0].address_components.filter(ac=>~ac.types.indexOf('sublocality'))[0].long_name
    //                             let City = results[0].address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name
    //                             let city_url = `${cityArea}, ${City}`;

    //                             $router.push(`/${city_url}`);
    //                             $store.commit('SET_CITY', city_url);
    //                             $store.dispatch("getVenues");
    //                         }
    //                     })
    //                 })
    //             });
    //         },
</script>