import { createApp } from 'vue'
import { addDevtools } from "./devtools_support";

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

//Echarts
import ECharts from 'vue-echarts'
import { use } from "echarts/core"

// import ECharts modules manually to reduce bundle size
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
])
//end echarts

import App from './App.vue'
import TopNavigation from './components/TopNavigation.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from "vue-gtag"

import VueGoogleMaps from '@fawmi/vue-google-maps';
//font awesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(fas, faFacebook, faTwitter);


//router
import router from "./router";

//style sheets
require('@/scss/main.scss');

import store from './store/index'


const app = createApp(App, {
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'top-navigation': TopNavigation
    }
});
app.config.devtools = true

app.config.productionTip = false

app.directive("click-outside", {
    beforeMount: function (el, binding) {
        // Define ourClickEventHandler
        const ourClickEventHandler = event => {
            if (!el.contains(event.target) && el !== event.target) {
                // as we are attaching an click event listern to the document (below)
                // ensure the events target is outside the element or a child of it
                binding.value(event); // before binding it
            }
        };
        // attached the handler to the element so we can remove it later easily
        el.__vueClickEventHandler__ = ourClickEventHandler;

        // attaching ourClickEventHandler to a listener on the document here
        document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function (el) {
        // Remove Event Listener
        document.removeEventListener("click", el.__vueClickEventHandler__);
    }
})

app.component('v-chart', ECharts);

if (process.env.NODE_ENV === 'production') {
  console.log('loading gtag api')
  app.use(VueGtag, {
    config: { 
      id: "G-454L9Y8DNF",
    }, router

});
}

app
    .use(store)
    .use(router)
    .use(PrimeVue)
    .use(VueGoogleMaps, {
        load: {
          key: "AIzaSyApTGj-NJ8_VbSWhbD8nBsnnSQOJRuLjNE",
          libraries: "places"
        }
    })
    .use(VueAxios, axios)
    .mount('#app');


if (process.env.NODE_ENV === 'development') {
  addDevtools(app, store)
}