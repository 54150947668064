<template>      
<div class=""> 
    <featured-event></featured-event>
    <top-search></top-search>

    
    <div class="events-Container">        
        <article v-for="venue in venues" :key="venue.id" >
            <router-link :to="{name: 'Venue', params: {slug: venue?.slug}}" @click="venueClicked(venue.slug)">
                <div class="event-Card">

                    <div class="event-cardImage">
                        <img v-bind:src="venue?.image" />
                    </div>

                    <div class="event-cardContent">
                        <span class="event-Category">{{venue?.category.name}}</span>
                        <h2 class="subtitle-1">{{ venue?.name }} </h2>
                        <div class="content" v-html="venue?.description"></div>
                        
                        <h2 class="subtitle-2">Quickfeel</h2>
                        <rating :venue="venue" :key="ratingKey"></rating>  
                        
                        <div class="event-cardAttribute">
                            <div class="card-eventIcon " title=""><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" class="svg-inline--fa fa-map-marker-alt fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg>
                            </div>
                            <span>
                                {{ venue?.location }}</span>
                            <!-- </a> -->
                        </div>
                    </div>

                </div>    
        </router-link>
        </article>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import FeaturedEvent from '../components/FeaturedEvent.vue';
import Rating from '../components/Rating.vue';
// import SideNavigation from '../components/SideNavigation.vue';
import TopSearch from '../components/TopSearch.vue';

export default {
  components: { TopSearch, FeaturedEvent, Rating },
  name: 'Home',
  mounted() {
      console.log('remounted home');      
      this.$store.commit('SET_CITY', this.city_slug);
      this.$store.commit('SET_DISTRICT', this.district_slug);
      this.$store.commit('SET_CATEGORY', this.category_slug);
      this.$store.dispatch("getVenues");
  },
  data() {
    return {
        ratingKey: 0,
    }
  },
  props: {
      city_slug: {
          type: String,
          required: false
      },
      district_slug: {
          type: String,
          required: false
      },
      category_slug : {
        type: String,
        required: false
      }
  },
  methods: {
    venueClicked(slug) {
        this.$store.commit('CLEAR_SELECTED_VENUE', slug);
        this.$store.dispatch("getSelectedVenue",  slug);
    },

    forceRerenderRating() {
      // Breaking cache by updating the ID
      this.ratingKey += 1;
    },
    updateDocumentTitle() {
        let documentTitle = ''
        if(this.category_slug == 'coworking-spaces') {
            documentTitle = `Coworking spaces in ${this.city_slug?.replace(/,/g, " ")}`
        }
        else {
            documentTitle = `Coffeeshops in ${this.city_slug?.replace(/,/g, " ")}`
        }
        document.title = documentTitle;
    }
  },
  watch: {
      venues() {
          this.forceRerenderRating();
          this.updateDocumentTitle();
      }
  },
  computed: 
    // Getting mapped states from vuex
    mapState(['venues']) 
}
</script>
