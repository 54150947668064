<template>
<div class="dashboard" v-bind:class="{ 'is-open': sideNavActive }">
  <!-- left panel -->
  <div class="dashboard__panel" v-click-outside="onClickOutside" >
    <aside class="dashboard__menu">
      <p class="dashboard__header">
        Categories
      </p>
      <ul class="dashboard__list">
        <li><a @click="handleCategoryClick({id: 1, name: 'Live Music'})">Live Music</a></li>
        <li><a @click="handleCategoryClick({id: 2, name: 'Food & Beverage'})">Food & Beverage</a></li>
      </ul>
    </aside>
  
  </div>
</div>

<nav id="events--nav" class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
  <a class="navbar-item" @click="toggleSideBar()">
    <font-awesome-icon icon="bars" />
  </a>

  <div class="navbar-item">
    <router-link to="/" class="subtitle-1">Coffee - Inside out</router-link>
    <!-- <span style="line-height: 30px; margin-left: 5px;" class="mgl-small">Inside Out Guides</span> -->
  </div>
  <div class="navbar-item">
    
    <router-link to="/suggestion">Suggest a new place</router-link>
    <!-- <span style="line-height: 30px; margin-left: 5px;" class="mgl-small">Inside Out Guides</span> -->
  </div>

    <a @click="handleCalendarClick()" role="button" class="navbar-item">
        <font-awesome-icon icon= "calendar-alt" />
    </a>  
    
</nav>
<!-- <div v-if="selectedCategory != null" style="display: flex;
    justify-content: flex-start;
    alignt-items: center;
    padding: 5px 25px 5px 25px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px">
  <h2 style="line-height: 1.3rem; margin-right: 0.5rem;">{{selectedCategory.name}}</h2>
  <span @click="removeCategory()" class="category--toggle-button"> 
    <font-awesome-icon icon="times" />
  </span>
</div> -->

<div class="v-date--wrapper" v-bind:class="{ 'is-active': calendarActive }">
    <v-date-picker  is-range="true" v-model="timeInterval" />
</div>

</template>

<script>
import { useRoute } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'TopNavigation',
  components: { FontAwesomeIcon },

  props: ['selectedCity'],
  setup () {
    
  },
  data() {
      return {
          selectedInterval: '',
          sideNavActive: false,
          sideBarOpenClicked: false,
          selectedCategory: 0,
          calendarActive: false,
          timeInterval: new Date(),
          whenOptions: [
            'Today',
            'Tomorrow',
            'This Week',
            'Any Time'
        ],
        current_route: ''
        
      }
  },
  mounted() {
    const route = useRoute();
    this.current_route = route;
    console.log(this.selectedCity);
  },
  created() {
    
  },
  methods: {
      handleDateClicked(e) {
          this.selectedInterval = e;
          let today = new Date();
          switch(e) {
            case this.whenOptions[0]:
                // today
                this.timeInterval = {
                    start: new Date(today.setDate(today.getDate())),
                    end: new Date(today.setDate(today.getDate()))
                }   
                break;
            case this.whenOptions[1]:   
                // tomorrow
                this.timeInterval = {
                    start: new Date(new Date().setDate(today.getDate() + 1)),
                    end: new Date(new Date().setDate(today.getDate() + 1))
                }   
                break;
            case this.whenOptions[2]:  
            //this week
                var curr = new Date; // get current date
                var first = curr.getDate() - curr.getDay()+1; // First day is the day of the month - the day of the week
                var last = first + 6; // last day is the first day + 6

                var firstday = new Date(curr.setDate(first)).toUTCString();
                var lastday = new Date(curr.setDate(last)).toUTCString();

                this.timeInterval = {
                    start: new Date(firstday),
                    end: new Date(lastday)
                }  

                break;
            case this.whenOptions[3]:   
            //next week
                // var curr2 = new Date; // get current date
                // var first2 = curr2.getDate() - curr2.getDay()+8; // First day is the day of the month - the day of the week
                // var last2 = first2 + 6; // last day is the first day + 6

                // var firstday2 = new Date(curr2.setDate(first2)).toUTCString();
                // var lastday2 = new Date(curr2.setDate(last2)).toUTCString();

                // this.timeInterval = {
                //     start: new Date(firstday2),
                //     end: new Date(lastday2)
                // }  
                //console.log('any time');
                this.timeInterval = 0;
                
                // this.$emit('updated-interval', this.timeInterval);

                break;
          }
          // this.$store.commit('SET_TIMERANGE', this.timeInterval);
          // this.$store.dispatch("getEvents");
      },
      
      toggleSideBar() {
        this.sideBarOpenClicked=true;
          this.sideNavActive = !this.sideNavActive;
      },
      handleCalendarClick() {
          this.calendarActive = !this.calendarActive;
      },
      handleCategoryClick(category){
        this.selectedCategory = category;
      },
      
      removeCategory(){
        this.selectedCategory = null;
      },
      onClickOutside(e) {
        e;
        if(this.sideBarOpenClicked == true) {
          this.sideBarOpenClicked = false;
        }
        else {
          this.sideNavActive = false;
        }
      },
      is_event_page() {
        this.current_route.name == "Event"
        //console.log(this.current_route.name)
      }
      
  },
  watch: {
      selectedCategory() { 
        // this.$store.commit('SET_CATEGORY', this.selectedCategory?.id);
        // this.$store.dispatch("getEvents");
        
      },
      selectedCity() {
          // this.$store.commit('SET_CITY', this.selectedCity);
          // this.$store.dispatch("getEvents");
      },
      timeInterval: function() {
        // this.$store.commit('SET_TIMERANGE', this.timeInterval);
        // this.$store.dispatch("getEvents");
      }
  }
}
</script>